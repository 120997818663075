<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t("Copying a marketer profile") }}
    </p>
    <section class="sm-content mt-25">
      <div class="filter-block bdbr-1">
        <label for="" class="d-block weight-700 mb-7">{{ $t("Original product") }}</label>
        <div class="d-flex justify-content-between form-group">
          <div class="form-item">
            <label>{{ $t('Distributor') }}</label>
            <div class="mt-2 mb-7 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('Everything')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t("Supplier") }}</label>
            <div class="mt-2 mb-7 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('Everything')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t('Client No') }}</label>
            <div class="mt-2 mb-7 icon-input d-flex justify-content-between">
              <input
                type="text"
                class="input search-input"
                :placeholder="$t('Everything')"
              />
              <button class="button filter__button"></button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sm-content mt-25">
      <div class="filter-block bdbr-1">
        <label for="" class="d-block weight-700 mb-7">{{ $t("Target product") }}</label>
        <div class="d-flex justify-content-between form-group">
          <div class="form-item">
            <label>{{ $t('Distributor') }}</label>
            <div class="mt-2 mb-7 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('Everything')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t("Supplier") }}</label>
            <div class="mt-2 mb-7 icon-input">
              <input
                type="text"
                class="input"
                :placeholder="$t('Everything')"
              />
              <img src="/assets/img/icons/search-light.svg" alt="" />
            </div>
          </div>
          <div class="form-item">
            <label>{{ $t('Client No') }}</label>
            <div class="mt-2 mb-7 icon-input d-flex justify-content-between">
              <input
                type="text"
                class="input search-input"
                :placeholder="$t('Everything')"
              />
              <button class="button filter__button"></button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <button class="primary-btn save-btn mt-25 mb-20">
      {{ $t('Save') }}
    </button>
  </main>
</template>

<script>
export default {
  name: 'SCResellerProfile',
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.form-item {
  width: calc(33% - 30px);
}
.filter-block {
  padding: 10px 20px;
}
.save-btn {
  margin: 0 auto;
}
.search-input {
  width: 70%;
}
@media screen and (max-width: 900px) {
  .form-group {
    flex-wrap: wrap;
  }
  .form-item { 
    width: 100%;
  }
  .save-btn {
    max-width: 90%;
  }
}
</style>